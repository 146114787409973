import styled from 'styled-components';
import { css } from "styled-components";


export const LinearContainer = styled.div`
width: 150px;
margin-right: 30px;
z-index: 0;

`
export const VagasPreenchidas = styled.div`
    display: flex;
    margin: 0px;
    padding: 0px;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 0;
`