import styled from 'styled-components';
import { css } from "styled-components";



export const StatusTurmasLock = styled.img`
    margin-right: 25%;
`
export const DisplayFlexLockTrashTurmas = styled.div`
    display: flex;
`
export const TrashImg = styled.img`
    margin-left: 30%;
`