import styled from 'styled-components';
import { css } from "styled-components";


export const Title = styled.p`
    font-size: 18px;
    font-weight: bolder;
    margin-left: 8%;
    margin-top: 8%;
    
`
export const DataEdit = styled.p`
    color: #9EA0A5;
    padding: 0px;
    margin: 0px;
    

`
/*export const LinearContainer = styled.div`
    width: 150px;
    margin-right: 30px;
    margin-top: 10px;
`*/
export const VagasPreenchidas = styled.div`
    display: flex;
    margin: 0px;
    padding: 0px;
`